import { navigationItems } from '@mfe/core-navigation';
import { Priority, registerApp } from '@mfl/framework';
import translations from './links.strings';
import { currentUser } from '@mfl/platform-shell';
import {
  LINKS_EDIT_PERMISSION,
  LINKS_MVP_ENTITLEMENT,
} from '@wisestamp/links-gateway-sdk';

const route = '/v1/links';

export async function register() {
  if (
    currentUser.isAllowedTo(LINKS_EDIT_PERMISSION) &&
    currentUser.isEntitledTo(LINKS_MVP_ENTITLEMENT)
  ) {
    await registerApp('links', route);

    navigationItems.register(
      {
        key: 'LINKS',
        text: translations.header,
        route: route,
        fontAwesomeIcon: 'link-simple',
      },
      { priority: Priority.Medium - 108 }
    );
  }
}
